// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const apiIp = 'https://api.jcosmos.net/'; // backend
const apiIp = 'https://stagingapi.jcosmos.net/'; // staging
// const apiIp = 'http://3.140.242.101/'; // old staging
// const apiIp = 'https://devapi.jcosmos.net/'; // new dev backend
// const apiIp = 'http://0.0.0.0:8000/'; // local backend

export const environment = {
  production: false,
  apiIp,
  apiUrl: apiIp + 'api/v1/',
  authApiUrl: apiIp + 'api/v1/user_info', // auth endpoint
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
